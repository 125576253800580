import React from "react";
import IsaoKatoBlog from "../../Components/IsaoKatoBlog";
import OfficialBlog from "../../Components/OfficialBlog";
import Staff from "../../Components/Staff";
import { imgDir } from "../../util";
import DteTabs from "./Tabs";
import VariousFeets from "./VariousFeets";
import { useDispatch } from "react-redux";
import { setTransitionStarter } from "../../redux/effectSlice";
import { useNavigate } from "react-router-dom";
import { VideoTag } from "react-video-tag";

const vassStyle = () => {
    const css = `
        #vassTypo {
            animation: vassAnimation 16s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes vassAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -1396px;
            }
        }
        #laszioTypo {
            animation: laszioAnimation 20s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes laszioAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -2175px;
            }
        }
        #saintTypo {
            animation: saintAnimation 20s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes saintAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -1888px;
            }
        }
        #vintageTypo {
            animation: vintageAnimation 20s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes vintageAnimation {
            0% {
                left:0px;
            }
            100% {
                left: -1068px;
            }
        }
    `
    return (
        <style>
            {css}
        </style>
    )
}

export default function DteMobile(props: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const linkTo = (path: string) => {
        dispatch(setTransitionStarter(true))
        setTimeout(()=>{
            navigate(path)
        }, 600)
    }
    return (
        <>
            {vassStyle()}
            <section className="h-screen w-full flex justify-center items-center" style={{backgroundImage: 'url('+ imgDir() + '/d-te/fv-mobile.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div>
                    <img className="w-60 h-auto" src={imgDir() + '/logo-d-te.svg'} alt="" />
                    <h2 className="text-white flex justify-center items-center mt-4"><span className="inknut text-3xl tracking-wide">Chapter</span><span className="ruqaa text-7xl mb-6 ml-3">2</span></h2>
                    <h1 className="text-white text-lg text-center font-bold">新たなステージ<br />と<br />D-TE SHOE STORE</h1>
                </div>

            </section>
            <section className="section bg-[#efede5]">
                <div className="relative w-full h-60 overflow-hidden">
                    <div className='absolute top-0 left-[20vw] w-[380px] h-[220px]'>
                        <VideoTag autoPlay muted loop playsInline src={imgDir() + '/d-te/d-te.mp4'}  className='w-[380px] h-[214px] object-contain' />
                        <div className="absolute inset-0 shadow-[inset_0px_0px_15px_15px_rgba(239,237,229,1)]">
                        </div>
                    </div>
                </div>
                <div className=" px-8 lg:px-0 mx-auto">
                    <DteTabs />
                </div>
            </section>

            <section className="section bg-[#F0F0F0] py-20 relative">
                <div className="relative mx-auto">
                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                    <div className="relative w-screen overflow-hidden">
                        <div id="vassTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 748*2*3 + 'px'}}>
                            <div>
                                VASS SHOES
                            </div>
                            <div>
                                VASS SHOES
                            </div>
                            <div>
                                VASS SHOES
                            </div>
                        </div>
                        <img src={imgDir() + '/d-te/vass/shoe.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                    </div>
                    <h3 className="text-center font-bold tracking-wider text-3xl">Vass Shoes</h3>
                    <p className=" text-sm mt-8 leading-6 zen px-8">
                    伝説的靴職人のラズロ・ヴァーシュ氏が1978年にハンガリーで立ち上げたブランド。トラディショナルでありながらも圧倒的な存在感を放ちます。
                    </p>
                    <div className="flex justify-center mt-8">
                        <button onClick={()=>linkTo("/d-te/vass-shoes")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                            <div className="absolute inset-0 border border-black ">
                            </div>
                            MORE
                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                            MORE
                            </div>
                        </button>
                    </div>
                </div>
            </section>

            <section className="section bg-[#F0F0F0] py-20 relative">
                <div className="relative">
                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                    <div className="relative w-screen overflow-hidden">
                        <div id="laszioTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 1248*2*3 + 'px'}}>
                            <div className="uppercase">
                                Laszlo Budapest
                            </div>
                            <div className="uppercase">
                                Laszlo Budapest
                            </div>
                            <div className="uppercase">
                                Laszlo Budapest
                            </div>
                        </div>
                        <img src={imgDir() + '/d-te/laszlo/shoe.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                    </div>
                    <h3 className="text-center font-bold tracking-wider text-3xl">Laszlo Budapest</h3>
                    <p className=" text-sm zen mt-8 leading-6 px-8">
                    ヨーロッパ有数の靴生産地であるハンガリーで100年以上の歴史を持つ、隠れた名門。<br />ハンドメイドの靴作りの技術を、職人たちが今も守り続けています。
                    </p>
                    <div className="flex justify-center mt-8">
                        <button onClick={()=>linkTo("/d-te/laszlo-budapest")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                            <div className="absolute inset-0 border border-black ">
                            </div>
                            MORE
                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                            MORE
                            </div>
                        </button>
                    </div>
                </div>
            </section>

            <section className="section bg-[#F0F0F0] py-20 relative">
                <div className="relative mx-auto">
                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                    <div className="relative w-screen overflow-hidden">
                        <div id="saintTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 1248*2*3 + 'px'}}>
                            <div className="uppercase">
                            Saint Crispin's
                            </div>
                            <div className="uppercase">
                            Saint Crispin's
                            </div>
                            <div className="uppercase">
                            Saint Crispin's
                            </div>
                        </div>
                        <img src={imgDir() + '/d-te/saint_crispins/shoe.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                    </div>
                    <h3 className="text-center font-bold tracking-wider text-3xl">Saint Crispin's</h3>
                    <p className=" text-sm zen mt-8 leading-6 px-8">
                    シューズデザイナーであるマイケル・ローリック氏によって、1992年にオーストリアで設立されたシューズブランド。
                    </p>
                    <div className="flex justify-center mt-8">
                        <button onClick={()=>linkTo("/d-te/saint-crispins")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                            <div className="absolute inset-0 border border-black ">
                            </div>
                            MORE
                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                            MORE
                            </div>
                        </button>
                    </div>
                </div>
            </section>

            <section className="section bg-[#F0F0F0] py-20 relative">
                <div className="relative mx-auto">
                    <h6 className="text-center font-bold tracking-wider">the brands we deal with</h6>
                    <div className="relative w-screen overflow-hidden">
                        <div id="vintageTypo" className="absolute top-6 left-0 flex space-x-10 text-[240px] cormorant text-white" style={{width: 1248*2*3 + 'px'}}>
                            <div className="uppercase">
                            VINTAGE
                            </div>
                            <div className="uppercase">
                            VINTAGE
                            </div>
                            <div className="uppercase">
                            VINTAGE
                            </div>
                        </div>
                        <img src={imgDir() + '/vintage@2x.png'}  className="relative block mx-auto h-[300px] mt-8 mb-4"/>
                    </div>
                    <h3 className="text-center font-bold tracking-wider text-3xl">VINTAGE</h3>
                    <p className=" text-sm zen mt-8 leading-6 px-8">
                    次の世代へと受け継ぐことができる…。それも革靴の大きな魅力です。履かれなくなった靴に、新たな一歩を与えてください。
                    </p>
                    <div className="flex justify-center mt-8">
                        <button onClick={()=>linkTo("/d-te/vintage")} className="relative block w-40 py-2 text-sm tracking-wider font-bold group bg-white text-black">
                            <div className="absolute inset-0 border border-black ">
                            </div>
                            MORE
                            <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                            MORE
                            </div>
                        </button>
                    </div>
                </div>
            </section>

            <section className="bg-white py-10">
                <h5 className="text-center font-bold">靴を楽しむ足元</h5>
                <h4 className="mt-4 text-center text-5xl cormorant tracking-wide">VARIOUS<br />FEET</h4>
                <VariousFeets />
            </section>

            <Staff />
            <OfficialBlog />
            <IsaoKatoBlog />

            <div className="bg-white md:py-20">
                <div className="md:w-[700px] mx-auto">
                    <div className="md:grid grid-cols-2 gap-4">
                        <div>
                            <button onClick={()=>linkTo("/cento-trenta")} className="h-[250px] md:h-[528px] flex w-full justify-center items-center relative group" >
                                <img className="md:hidden absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter1bw-mobile.jpg'}/>

                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter1bw.jpg'}/>
                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover opacity-0 transition-opacity group-hover:opacity-100 duration-500" src={imgDir() + '/top/chapter1.jpg'}/>
                                <div className="relative">
                                    <h5 className="text-white flex justify-center items-center"><span className="inknut text-2xl md:text-4xl tracking-wide">Chapter</span><span className="ruqaa text-6xl md:text-7xl mb-5 ml-2">1</span></h5>
                                    <h4 className="text-white text-center mt-4 font-bold">はじまり<br />と<br />CENTO TRENTA</h4>
                                </div>
                            </button>
                        </div>
                        <div>
                            <button onClick={()=>linkTo("/order-suit")} className="h-[250px] md:h-[528px] flex w-full justify-center items-center relative group" >
                                <img className="md:hidden absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter3bw-mobile.jpg'}/>
                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover" src={imgDir() + '/top/chapter3bw.jpg'}/>
                                <img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover opacity-0 transition-opacity group-hover:opacity-100 duration-500" src={imgDir() + '/top/chapter3.jpg'}/>

                                <div className="relative">
                                    <h5 className="text-white flex justify-center items-center"><span className="inknut text-2xl md:text-4xl tracking-wide">Chapter</span><span className="ruqaa text-6xl md:text-7xl mb-5 ml-2">3</span></h5>
                                    <h4 className="text-white text-center mt-4 font-bold">チェント・トレンタ<br />と<br />オーダースーツ</h4>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}